@import "./scss/partials/mixins";
@import "./scss/partials/variables";

@include google-font("Noto+Sans+Display");
@include google-font("Oswald");
@include google-font("Arizonia");

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.bg-blur {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background-color: $blur_1;
	}
	.bg-blur2 {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: $blur_2;
	}
	.bg-blur3 {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		background-color: $blur_3;
	}
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.service-info {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		background-color: $blur_4;
	}
	.been-shipped {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		background-color: $blur_5;
	}
	.main-page-login {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(5px);
		background-color: $blur_6;
	}
	.category-tags {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		background-color: $blur_7;
	}
}

.home {
	background: {
		repeat: no-repeat;
		attachment: fixed;
		position: center;
		size: cover;
		image: linear-gradient(
				0deg,
				$cloudinary_homeImage_gradient_from,
				$cloudinary_homeImage_gradient_via,
				$cloudinary_homeImage_gradient_to
			),
			url($cloudinary_homeImage);
	}
}

// Hide scrollbar for Chrome, Safari and Opera
* {
	margin: 0;
	padding: 0;
	box-shadow: border-box;
}

.general-scrollbar-hide::-webkit-scrollbar {
	display: none;
}

html {
	scroll-behavior: smooth;
}

input:focus,
textarea:focus,
select:focus {
	outline: 0 !important;
}
*:focus {
	outline: 0;
}

// Cursive font
.font_cursive {
	font-family: "Arizonia";
}

// Progressive bar
progress {
	@include progress(5px);
	@include border(none);
	@include border_rad(30px);
}

progress::-moz-progress-bar {
	@include background(lightcolor);
}

progress::-webkit-progress-value {
	@include border_rad(30px);
	@include background(green);
}

progress::-webkit-progress-bar {
	@include border_rad(30px);
	@include background(rgb(48, 48, 48));
}

// CHECKBOX The container
.check-container {
	display: block;
	position: relative;
	padding-left: 35px;
	/* margin-bottom: 12px; */
	cursor: pointer;
	font-size: 22px;
	@include browser(none, none, none, none);

	/* Hide the browser's default checkbox */
	input {
		/* position: absolute; */
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
}

/* Create a custom checkbox */
.checkmark {
	@include absolute(absolute, "", "", 0, "");
	@include border(1px solid rgb(65, 65, 65));
	@include bg_color(transparent);
	margin-top: 3px;
	height: 25px;
	width: 25px;
}
.checkmark2 {
	@include absolute(absolute, "", "", 0, "");
	@include border(1px solid rgb(15 23 42));
	@include bg_color(transparent);
	margin-top: 3px;
	height: 25px;
	width: 25px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
	@include bg_color(#ccc);
}
.check-container:hover input ~ .checkmark2 {
	@include bg_color(#ccc);
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
	@include bg_color(black);
}
.check-container input:checked ~ .checkmark2 {
	@include bg_color(rgb(15 23 42));
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	@include pseudo_element("", absolute, none);
}
.checkmark2:after {
	@include pseudo_element("", absolute, none);
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
	display: block;
}
.check-container input:checked ~ .checkmark2:after {
	display: block;
}

/* Style the checkmark/indicator */
.check-container {
	.checkmark:after,
	.checkmark2:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

/* The container */
.radio-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	@include browser(none, none, none, none);
}

/* Hide the browser's default radio button */
.radio-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmark-radio {
	@include absolute(absolute, 0, "", 0, "");
	@include bg_color(#eee);
	@include border_rad(50%);
	height: 25px;
	width: 25px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark-radio {
	@include bg_color(#ccc);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark-radio {
	@include bg_color(rgb(15 23 42));
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
	@include pseudo_element("", absolute, none);
}

// Show the indicator (dot/circle) when checked
.radio-container input:checked ~ .checkmark-radio:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark-radio:after {
	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	@include border(50%);
	@include background(white);
}

// Sub menu
.wrapper {
	position: absolute;
	transform: translate(-50%, -50%);
	z-index: 5;

	.mainMenu {
		width: 100%;
		display: block;
		overflow: hidden;

		.item {
			width: 105%;
			overflow: hidden;
			list-style: none;

			._btn {
				display: block;
				padding: 15px 20px;
				width: 100%;
				color: #fff;
				position: relative;
				text-transform: uppercase;
				font-size: 12px;
				margin-right: 10px;

				&:before {
					content: "";
					@include absolute(absolute, "", -10px, "", 30px);
					width: 0;
					height: 0;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					@include border_all(
						"",
						"",
						8px solid transparent,
						8px solid transparent
					);
					z-index: 9;
				}
			}
		}
	}
}
.subMenu {
	@include background(#0f172a);
	overflow: hidden;
	transition: max-height 0.7s;
	max-height: 0;
	span {
		display: block;
		padding: 15px 20px;
		color: #fff;
		font-size: 14px;
		border-bottom: 1px solid #18284d;
		position: relative;
		cursor: pointer;
	}
	span:before {
		content: "";
		opacity: 0;
		transition: opacity 0.3s;
	}
	span:hover:before {
		content: "";
		@include absolute(absolute, 0, "", 0, "");
		height: 0;
		width: 6px;
		opacity: 1;
		@include border_all(
			24px solid transparent,
			24px solid transparent,
			11px solid #ff9d00,
			""
		);
	}
	span:after {
		content: "";
		opacity: 0;
		transition: opacity 0.3s;
	}
	span:hover:after {
		content: "";
		@include absolute(absolute, 0, "", "", 0);
		height: 0;
		width: 6px;
		opacity: 1;
		@include border_all(
			24px solid transparent,
			24px solid transparent,
			"",
			11px solid #ff9d00
		);
	}
	span:hover {
		@include background(#273057);
		background: linear-gradient(
			to bottom,
			#273057 0%,
			#273057 50%,
			#394c7f 51%,
			#394c7f 100%
		);
		filter: progid:DXImageTransform.Mocrosoft.gradient(startColorstr='#273075', endColorstr='#394c7f', GradientType=0);
		transition: all 0.3s;
		border-bottom: 1px solid #394c7f;
	}
	span:last-child {
		border: none;
	}
}

.item:target .subMenu {
	max-height: 10em;
}

// Card
.card {
	width: 300px;
	height: 400px;
	background: #000;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	/* cursor: pointer; */
	transition: all 0.25s ease;
	backface-visibility: hidden;

	button {
		margin-top: 10px;
		opacity: 0;
		cursor: pointer;
		outline: none;
		font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
			"Lucida Sans Unicode", Geneva, Verdana, sans-serif;
	}

	.con-text {
		z-index: 10;
		position: absolute;
		bottom: 0px;
		color: #fff;
		padding: 20px;
		padding-bottom: 30px;

		h2 {
			font-size: 20px;
			margin-bottom: 70px;
		}

		p {
			color: white;
			font-size: 0.8rem;
			opacity: 0;
			margin-bottom: -170px;
			transition: all 0.25s ease;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;
			font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
				"Lucida Sans Unicode", Geneva, Verdana, sans-serif;
		}
	}

	img {
		height: 100%;
		object-fit: cover;
		z-index: 10;
		transition: all 0.25s ease;
	}

	&:hover {
		transform: scale(0.9);

		button {
			opacity: 1;
		}

		img {
			transform: scale(1.25);
			mask-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.65) 100%
			);
			-webkit-mask-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.65) 100%
			);
		}
		.ul {
			transform: translate(0);
			opacity: 1;
		}
	}
	&:hover:after {
		height: 280px;
	}
	&:hover .con-text p {
		margin-bottom: 30px;
		opacity: 1;
	}
	&:hover .con-text h2 {
		margin-bottom: 0px;
	}
	&:after {
		width: 100%;
		content: "";
		left: 0px;
		bottom: 0px;
		height: 150px;
		position: absolute;
		background: linear-gradient(180deg, rgba(0, 0, 0, 100%));
		z-index: 20;
		transition: all 0.25s ease;
	}
}

.ul {
	position: absolute;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 40;
	border-radius: 14px;
	padding-left: 0px;
	padding-top: 8px;
	padding-bottom: 8px;
	top: 0px;
	opacity: 0;
	transform: translate(100%);
	transition: all 0.25s ease;

	li {
		background: #fff;
		list-style: none;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.7;
		transition: all 0.25s ease;
		backface-visibility: hidden;

		svg {
			font-size: 1.4rem;
			color: #000;
		}

		&:last-child {
			border-radius: 0px 0px 12px 12px;
			&:first-child {
				border-radius: 12px 12px 0px 0px;
				&:hover {
					opacity: 1;
					transform: translate(-7px, -4px);
					border-radius: 6px;
				}
			}
		}
	}
}

// Slide show
.active {
	color: rgb(199, 0, 0);
	transition: background 0.5s ease;
}
.slide {
	@include image-slide(0, 1s ease, scale(0.8));
}
.slide.active {
	@include image-slide(1, 1s, scale(1));
}
.slider {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.image {
	@include border_rad(10px);
	max-width: 500px;
	object-fit: cover;
}
.right-arrow,
.left-arrow {
	@include absolute(absolute, 45%, "", "", "");
	font-size: 1.5rem;
	color: red;
	z-index: 10;
	cursor: pointer;
	user-select: none;
}
.circle {
	width: 20px;
	height: 20px;
	@include absolute(absolute, "", "", 15%, "");
	@include border_rad(50%);
	@include bg_color(#d48d37);
	transform-origin: 50%;
	animation: circle 0.5s alternate infinite ease;
}
.circle:nth-child(2) {
	left: 42%;
	animation-delay: 0.2s;
	background-color: #ff00a2;
}
.circle:nth-child(3) {
	left: auto;
	right: 15%;
	animation-delay: 0.3s;
	background-color: #3300ff;
}
@keyframes circle {
	0% {
		top: 60px;
		height: 5px;
		border-radius: 50px 50px 25px 25px;
		transform: scale(1.7);
	}
	40% {
		height: 20px;
		border-radius: 50%;
		transform: scale(1);
	}
	100% {
		top: 0%;
	}
}
.load-wrapper {
	width: 120px;
	height: 60px;
	position: absolute;
	/* left: 50%;
	top: 10%; */
	transform: translate(-50%, -50%);
}
.social-media-list {
	display: flex;
}
.social-media-list {
	display: flex;
}
.social-media-list a {
	display: inline-block;
	width: 60px;
	height: 60px;
	@include bg_color(transparent);
	display: grid;
	place-items: center;
	border-radius: 50%;
	font-size: 28px;
	text-decoration: none;
	color: #fff;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.social-media-list a:not(:last-child) {
	margin-right: 2rem;
}
.social-media-list a svg {
	transition: transform 0.3s;
}
.social-media-list a::before {
	content: "";
	@include absolute(absolute, 15%, "", 15%, "");
	width: 115%;
	height: 115%;
	background-color: #3b3b3b;
	z-index: -1;
	transform: rotate(45deg);
	transition: transform 0.3s;
}
.social-media-list a:hover {
	color: #d48d37;
}
.social-media-list a:hover svg {
	transform: scale(1.2);
}
.social-media-list a:hover::before {
	transform: rotate(45deg) translate(15%, 100%);
}
.navStyleChild {
	position: relative;
}
.navStyleChild::after {
	@include absolute(absolute, "", 0, 50%, "");
	@include bg_color(rgb(133 77 14));
	content: "";
	width: 0%;
	height: 1px;
	transition: all 0.3s ease-in-out;
}
.navStyleChild:hover::after {
	width: 100%;
	left: 0;
}
.navStyleChildWhite {
	position: relative;
}
.navStyleChildWhite::after {
	@include absolute(absolute, "", 0, 50%, "");
	content: "";
	width: 0%;
	height: 1px;
	background-color: rgb(255, 255, 255);
	transition: all 0.3s ease-in-out;
}
.navStyleChildWhite:hover::after {
	width: 100%;
	left: 0;
}
.progress {
	@include progress(2px, 80%, 0 auto, $grey-100, absolute, hidden);

	.inner {
		@include inner();
	}
}
@keyframes progress {
	from {
		@include from;
	}
	to {
		@include to;
	}
}
.btn {
	@include bg_color(#fff);
	@include border_rad(100px);
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 5px;
	padding-bottom: 5px;

	&:hover {
		background-color: darken(#fff, 50%);
		color: #fff;
		font-weight: lighter;
		@include transition(0.5s);
	}
}
