$primary-0: #f0efff;
$primary-50: #d8d6ff;
$primary-100: #c1beff;
$primary-200: #aaa5ff;
$primary-300: #938dff;
$primary-400: #7b74ff;
/* main */
$primary-500: #645cff;
$primary-600: #554ed9;
$primary-700: #4640b3;
$primary-800: #37338c;
$primary-900: #282566;
$primary-1000: #191740;
$primary-1100: #0a0919;
/* grey */
$grey-0: #fafbfc;
$grey-50: #f2f4f8;
$grey-100: #eaedf3;
$grey-200: #e2e7ef;
$grey-300: #dbe0ea;
$grey-400: #d3dae6;
$grey-500: #cbd3e1;
$grey-600: #adb3bf;
$grey-700: #8e949e;
$grey-800: #70747c;
$grey-900: #51545a;
$grey-1000: #333538;
$grey-1100: #141516;
/* Purple */
$purple-0: #f5ecfe;
$purple-100: #ebddfe;
$purple-200: #e0cefe;
$purple-300: #d6bffe;
$purple-400: #cbaffe;
$purple-500: #c1a0fe;
$purple-600: #b691fe;
$purple-700: #ac82fe;
$purple-800: #a77afe;
$purple-900: #a172fd;

/* pink */
$pink-100: #fff0f3;
$pink-200: #ffccd5;
$pink-300: #ffb3c1;
$pink-400: #ff8fa3;
$pink-500: #ff758f;
$pink-600: #ff4d6d;
$pink-700: #c9184a;
$pink-800: #a4133c;
$pink-900: #800f2f;
$pink-1000: #590d22;

/* rose gold */
$roseGold-100: #d0a1a8;
$roseGold-200: #c89098;
$roseGold-300: #bf7f89;
$roseGold-400: #b76e79;
$roseGold-500: #af5d69;
$roseGold-600: #a2505d;
$roseGold-700: #914853;

/* rest */
$black: #222;
$white: #fff;
$red-light: #f8d7da;
$red-dark: #842029;
$green-light: #d1e7dd;
$green-dark: #0f5132;

/* box shadows */
$shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
	0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
	0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-5: 0px 0px 10px rgba(0, 0, 0, 0.01),
	0 10px 10px -5px rgba(0, 0, 0, 0.03);
$shadow-6: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0);
/* typography */
$defaultFontSize: 87.5%;
$headingFont: "Noto Sans Display", monospace;
$bodyFont: "Noto Sans Display", sans-serif;
$Oswald: "Oswald";
$smallText: 0.8em;
$extraSmallText: 0.7em;
/* rest */
$backgroundColor: var($grey-0);
$textColor: var($grey-1000);
$borderRadius: 0.2rem;
$borderRadius2: 0.8rem;
$letterSpacing: 1px;
$transition: 0.3s ease-in-out all;
$max-width: 1520px;
$fixed-width: 600px;

// Blur background
$blur_1: rgba(255, 255, 255, 0.3);
$blur_2: rgba(255, 255, 255, 0.6);
$blur_3: rgba(0, 0, 0, 0.9);
$blur_4: rgba(0, 0, 0, 0.5);
$blur_5: rgba(255, 255, 255, 0.5);
$blur_6: rgba(0, 0, 0, 0.3);
$blur_7: rgba(255, 255, 255, 0.7);

// cloudinary home image
$cloudinary_homeImage: "https://res.cloudinary.com/dhdccvkyt/image/upload/v1667071157/hairposey/hairposey_home_img_ovqapr.jpg";
$cloudinary_homeImage_gradient_from: rgb(0, 0, 0);
$cloudinary_homeImage_gradient_via: rgba(0, 0, 0, 0.7);
$cloudinary_homeImage_gradient_to: rgba(255, 255, 255, 0.1);
